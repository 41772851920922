import { createContext, ReactNode, useContext, useRef, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChevronDown,
  ChevronFirst,
  ChevronLast,
  ChevronRight,
} from 'lucide-react';

import logo from '../../assets/Sonio-logo.png';

type SidebarContextType = {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

const SidebarContext = createContext<SidebarContextType>({
  expanded: true,
  setExpanded: () => {},
});

type SidebarProps = {
  children: ReactNode;
};

export default function Sidebar({ children }: SidebarProps) {
  const { expanded, setExpanded } = useContext(SidebarContext);

  return (
    <aside className="h-screen relative overflow-visible">
      <nav className="h-full flex flex-col bg-white border-r shadow-sm overflow-visible">
        <div className="p-4 pb-2 flex justify-between items-center">
          <img
            src={logo}
            className={`overflow-hidden transition-all ${
              expanded ? 'w-32' : 'w-0'
            }`}
          />
          <button
            onClick={() => setExpanded(curr => !curr)}
            className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
          >
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button>
        </div>

        <ul className="flex-1 px-3">{children}</ul>
      </nav>
    </aside>
  );
}

type SidebarItemProps = {
  icon: React.ReactNode;
  text: string;
  active?: boolean;
  alert?: boolean;
  link?: string;
  children?: React.ReactNode;
  defaultOpen?: boolean;
};

export function SidebarItem({
  icon,
  text,
  active,
  alert,
  link,
  children,
  defaultOpen = false,
}: SidebarItemProps) {
  const { expanded } = useContext(SidebarContext);
  const [open, setOpen] = useState(defaultOpen);
  const [popoverTop, setPopoverTop] = useState(0);

  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLLIElement>(null);
  const hasChildren = !!children;

  const handleClick = () => {
    if (hasChildren) {
      if (!expanded) {
        const rect = wrapperRef.current?.getBoundingClientRect();
        if (rect) {
          setPopoverTop(rect.top);
        }
        setShowPopup(prev => !prev);
      } else {
        setOpen(prev => !prev);
      }
    } else if (link) {
      navigate(link);
    }
  };

  return (
    <>
      <li
        ref={wrapperRef}
        className={`relative flex items-center justify-between py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group ${
          active
            ? 'bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800'
            : 'hover:bg-indigo-50 text-gray-600'
        }`}
        onClick={handleClick}
      >
        <div className="flex items-center">
          {icon}
          {expanded && <span className="ml-3">{text}</span>}
        </div>

        {hasChildren && expanded && (
          <span>
            {open ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
          </span>
        )}

        {alert && (
          <div className="absolute right-2 w-2 h-2 rounded bg-indigo-400 top-2"></div>
        )}
      </li>

      {/* Expanded children when sidebar is open */}
      {hasChildren && open && expanded && (
        <ul className="ml-6 border-l pl-3">{children}</ul>
      )}

      {!expanded && showPopup && hasChildren && (
        <div
          className="fixed ml-2 bg-white border rounded shadow-lg z-50 p-2"
          style={{
            top: `${popoverTop}px`,
            left: '70px',
          }}
        >
          <ul>
            {React.Children.map(children, child => {
              if (!React.isValidElement(child)) return null;

              const { link, text } = child.props;

              return (
                <li
                  onClick={() => {
                    if (link) {
                      navigate(link);
                      setShowPopup(false);
                    }
                  }}
                  className="px-3 py-2 hover:bg-gray-100 rounded cursor-pointer whitespace-nowrap"
                >
                  {text}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
export { SidebarContext };
