import { FC, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import PersonIcon from '@mui/icons-material/Person';
import Sidebar, { SidebarItem } from 'src/layouts/customLayout';
import { SidebarContext } from 'src/layouts/customLayout';

import SidebarTopSection from './Sidebar/SidebarTopSection';
import Header from './Header';

const SidebarLayout: FC = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const [expanded, setExpanded] = useState(true); // ✅ State is now in parent

  const menuItems = [
    {
      name: 'Transactions',
      icon: <BackupTableTwoToneIcon />,
      link: '',
      items: [
        { name: 'Overview', link: '/transactions/sessions' },
        { name: 'Statistics', link: '/transactions/statistics' },
      ],
    },
    {
      name: 'Users',
      icon: <PersonIcon />,
      link: '',
      items: [{ name: 'List', link: '/users' }],
    },
  ];

  return isAuthenticated ? (
    <SidebarContext.Provider value={{ expanded, setExpanded }}>
      <div className="flex h-screen">
        <Sidebar>
          {expanded && <SidebarTopSection />}
          <hr className="my-3" />
          {menuItems.map(item => {
            const isActiveParent =
              !!item.link &&
              location.pathname !== '/' &&
              location.pathname.startsWith(item.link);
            const isAnyChildActive = item.items?.some(
              sub => location.pathname === sub.link,
            );

            return (
              <SidebarItem
                key={item.name}
                icon={item.icon}
                text={item.name}
                link={item.link}
                active={isActiveParent || isAnyChildActive}
                defaultOpen={isAnyChildActive}
              >
                {item.items?.map(sub => (
                  <SidebarItem
                    key={sub.name}
                    icon={<span className="w-2 h-2 rounded-full bg-gray-400" />}
                    text={sub.name}
                    link={sub.link}
                    active={location.pathname === sub.link}
                  />
                ))}
              </SidebarItem>
            );
          })}
        </Sidebar>

        <div className="flex flex-col flex-1 overflow-hidden">
          <Header />
          <main className="flex-1 overflow-y-auto bg-gray-50">
            <Outlet />
          </main>
        </div>
      </div>
    </SidebarContext.Provider>
  ) : (
    <Outlet />
  );
};

export default SidebarLayout;
