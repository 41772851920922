import HeaderUserBox from './Userbox';

const Header = () => {
  return (
    <div className="flex items-center w-full z-6 h-[80px] text-primary bg-white px-4">
      <div className="ml-auto">
        <HeaderUserBox />
      </div>
    </div>
  );
};

export default Header;
